@use "../../assets/scss/main/colors" as colors;

.contact .contactform input[type="text"],
.contact .contactform input[type="email"],
.contact .contactform textarea {
  background-color: #fff;
  border: 1px solid #ddd;
  color: #666;
}

.contact li a {
  color: #666;
  background: #eee;
}

.contact li a:hover {
  color: #fff;
}
/* Form */
.contact .contactform input[type="text"],
.contact .contactform input[type="email"] {
  border: 1px solid #111;
  width: 100%;
  background: #252525;
  color: #fff;
  padding: 11px 26px;
  border-radius: 30px;
  outline: none !important;
  transition: 0.3s;
}

.contact .contactform .form-group {
  margin-bottom: 30px;
}

.contact {
  .container {
    padding-bottom: 100px;
  }

  @media screen and (min-width: 1920px) {
    .container {
      padding-bottom: 214px;
    }
  }

  .contactform textarea {
    border: 1px solid #111;
    background: #252525;
    color: #fff;
    width: 100%;
    padding: 12px 26px;
    height: 200px;
    overflow: hidden;
    border-radius: 30px;
    outline: none !important;
    transition: 0.3s;
  }
}

.contact .contactform .form-message {
  margin: 24px 0;
}

.contactform {
  padding-left: 30px;

  @media screen and (max-width: 991px) {
    padding-left: 0;
  }
}

.contact .contactform .output_message {
  display: block;
  color: #fff;
  height: 46px;
  line-height: 46px;
  border-radius: 30px;
}

.contact .contactform .output_message.success {
  background: #28a745;
}

.contact .contactform .output_message.success:before {
  font-family: FontAwesome;
  content: "\f14a";
  padding-right: 10px;
}

.contact .contactform .output_message.error {
  background: #dc3545;
}

.contact .contactform .output_message.error:before {
  font-family: FontAwesome;
  content: "\f071";
  padding-right: 10px;
}

@media (max-width: 991px) {
  .contact .main-content {
    padding-bottom: 0;
  }
}
/*** Contact ***/
.contact .main-content {
  padding-bottom: 85px;
}

/* Left Side */

.contact .custom-span-contact {
  font-weight: 600;
  padding-left: 50px;
  line-height: 25px;
  padding-top: 5px;
  margin-bottom: 20px;
}

.contact .custom-span-contact i {
  left: 0;
  top: 10px;
  font-size: 33px;
}

.contact .custom-span-contact i.fa-phone-square {
  font-size: 39px;
  top: 7px;
}

.contact .custom-span-contact span {
  text-transform: uppercase;
  opacity: 0.8;
  font-weight: 400;
  padding-bottom: 0;
}

.contact .custom-span-contact a {
  color: #000;
  text-decoration: none;
  &:hover {
    cursor: none;
  }
}

.contact .custom-span-contact a {
  color: #666;
}

.invalid-feedback {
  display: block;
}
