@use "../../assets/scss/main/colors" as colors;
.about .box-stats {
  border: 1px solid #ddd;
}

.about .c100,
.about .resume-box .time {
  background-color: #eee;
}

.about .resume-box .time,
.about .resume-box p {
  color: #666;
}

.about .resume-box .place:before {
  background-color: #666;
}

.about .resume-box li:after {
  border-left: 1px solid #ddd;
}

.about .box-stats h3 {
  color: colors.$primary-color;
}

.about .c100 > span {
  color: #666;
}

.about .c100:after {
  background-color: #fff;
}

.about .about-list .title {
  opacity: 0.8;
  text-transform: capitalize;
}

.about .about-list .value {
  font-weight: 600;
}

.about .box-stats {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #252525;
}

.about .box-stats.with-margin {
  margin-bottom: 10px;
}

.about .box-stats h3 {
  position: relative;
  display: inline-block;
  margin: 0;
  font-size: 50px;
  font-weight: 700;
}

.about .box-stats h3:after {
  content: "+";
  position: absolute;
  right: -24px;
  font-size: 33px;
  font-weight: 300;
  top: 2px;
}

.about .box-stats p {
  padding-left: 15px;
}

.about .box-stats p:before {
  content: "";
  position: absolute;
  left: 0;
  top: 13px;
  width: 10px;
  height: 1px;
  background: #777;
}

.about .resume-box ul {
  margin: 0;
  padding: 0 0 50px 0;
  list-style: none;
}

.about .resume-box li {
  position: relative;
  padding: 0 20px 0 60px;
  margin: 0 0 50px;
}

.about .resume-box li:after {
  content: "";
  position: absolute;
  top: 0;
  left: 20px;
  bottom: 0;
  border-left: 1px solid #333;
}

.about .resume-box .icon {
  width: 40px;
  height: 40px;
  position: absolute;
  left: 0;
  right: 0;
  line-height: 40px;
  text-align: center;
  z-index: 1;
  border-radius: 50%;
  color: #fff;
  background-color: #252525;
}

.about .resume-box .time {
  color: #fff;
  font-size: 12px;
  padding: 1px 10px;
  display: inline-block;
  margin-bottom: 12px;
  border-radius: 20px;
  font-weight: 600;
  background-color: #252525;
  opacity: 0.8;
}

.about .resume-box h5 {
  font-size: 18px;
  margin: 7px 0 10px;
}

.about .resume-box p {
  &.details {
    color: #252525;
  }
  margin: 0;
  color: #eee;
  font-size: 14px;
}

.about .resume-box .place {
  opacity: 0.8;
  font-weight: 600;
  font-size: 15px;
  position: relative;
  padding-left: 5px;
}

.about .c100 {
  float: none !important;
  margin: 0 auto;
  background-color: #252525;
}

.about .c100 > span {
  color: #fff;
}

.about .c100:after {
  background-color: #111;
}

.about .resume-box h5 {
  font-size: 17px;
}

.about .box-stats {
  padding: 15px 20px 20px 25px;
}

@media screen and (max-width: 460px) {
  .about .box-stats {
    padding: 2px;
    overflow: hidden;
  }
}

.about .c100 {
  transform: scale(0.8);
}

.about .box-stats.with-margin {
  margin-bottom: 10px;
}

.about .box-stats h3 {
  font-size: 40px;
}

.about .box-stats p {
  padding-left: 0;
}

.about .box-stats p:before {
  display: none;
}

.about-list {
  display: flex;
  flex-wrap: wrap;

  li {
    flex: 0 0 100%;
    max-width: 50%;
    @media screen and (max-width: 460px) {
      flex: 0 0 50%;
      max-width: 100%;
    }
  }
}

.container {
  font-size: 18px;
  ul.whyMe-list {
    li {
      margin-bottom: 20px;
    }
  }
}
.main-content {
  padding-top: 0;
}
