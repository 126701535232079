@use "../../assets/scss/main/colors" as colors;

.hero-component {
  .details-container {
    display: flex;
    align-items: center;
    height: 100vh;

    @media (max-width: 991px) {
      height: auto;
      padding: 20px;
      flex-direction: column; // Coloca imagen y texto en columna
    }
  }

  .background-image {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center top;
    height: calc(100vh - 80px);
    z-index: 11;
    border-radius: 30px;
    left: 40px;
    top: 40px;
    box-shadow: 0 0 7px rgba(0, 0, 0, 0.9);

    @media (max-width: 991px) {
      position: relative;
      width: 100%; // Imagen ajusta su ancho al contenedor
      height: auto; // Mantiene proporción
      max-height: 300px; // Máximo alto para evitar desbordes
      margin-bottom: 20px;
      border-radius: 15px;
    }

    @media (max-width: 576px) {
      max-height: 200px;
    }
  }

  .details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 20px;

    @media (max-width: 991px) {
      align-items: center;
      text-align: center;
    }

    > div {
      max-width: 550px;
      margin: 0 auto;

      @media (max-width: 1100px) and (min-width: 992px) {
        max-width: 450px;
      }

      @media (min-width: 1600px) {
        max-width: 600px;
      }
    }
  }

  .title {
    font-size: 3rem;
    line-height: 1.2;
    font-weight: 700;
    margin-bottom: 10px;
    color: colors.$primary-color;
    position: relative;

    span {
      display: block;
      font-size: 1.5rem;
      font-weight: 400;
      color: colors.$secondary-color;
      margin-top: 5px;
    }

    @media (max-width: 991px) {
      font-size: 2.5rem;

      span {
        font-size: 1.2rem;
      }
    }

    @media (max-width: 576px) {
      font-size: 2rem;

      span {
        font-size: 1rem;
      }
    }
  }

  .description {
    margin: 15px 0;
    font-size: 1.2rem;
    line-height: 1.8;
    color: colors.$dark-gray;

    @media (max-width: 991px) {
      font-size: 1rem;
      line-height: 1.6;
    }

    @media (max-width: 576px) {
      font-size: 0.9rem;
      line-height: 1.5;
    }
  }

  .social {
    margin-top: 30px;
    display: flex;
    justify-content: flex-start;

    @media (max-width: 991px) {
      justify-content: center; // Centra horizontalmente en resoluciones pequeñas
      width: 100%;
    }

    ul {
      display: flex;
      gap: 15px;

      @media (max-width: 576px) {
        gap: 10px;
      }
    }
  }
}
