.user-avatar {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 5px 10px;
  border-radius: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  .avatar-image {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    object-fit: cover;
    background-color: #ccc; // Fondo gris para cuando no hay imagen
  }

  .avatar-placeholder {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: #ccc;
    color: #fff;
    font-weight: bold;
    font-size: 14px;
    text-transform: uppercase;
  }

  .user-name {
    font-size: 10px;
    font-weight: 500;
    color: #030303;
  }
}
