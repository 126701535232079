@use "../../assets/scss/main/colors" as colors;

.header {
  position: fixed;
  right: 30px;
  z-index: 3;
  display: flex;
  align-items: center;
  top: 50%;
  opacity: 1;
  transition: opacity 0.3s;
  -webkit-transition: opacity 0.3s;
  transform: translateY(-50%);
  z-index: 10;

  @media (max-width: 991px) {
    left: 0;
    right: auto;
    bottom: 0;
    top: auto;
    transform: none;
    width: 100%;
    display: block;
    background: #2b2a2a;
    padding: 8px;

    ul.icon-menu {
      display: flex;
      align-items: center;
      justify-content: space-around;

      li.icon-box {
        margin: 0 !important;
        width: 45px !important;
        height: 45px !important;
        background: #444 !important;

        i {
          top: 14px;
        }
      }

      h2 {
        display: none;
      }
    }
  }

  @media (max-width: 360px) {
    ul.icon-menu {
      justify-content: space-between;
    }
  }
}
.header.hide-header {
  z-index: 0 !important;
  opacity: 0;
}

.header ul.icon-menu {
  margin: 0;
  padding: 0;
}

.header ul.icon-menu li.icon-box {
  width: 50px;
  height: 50px;
  list-style: none;
  position: relative;
  display: flex;
  align-items: center;
  transition: 0.3s;
  margin: 20px 0;
  border-radius: 50%;
  background: colors.$primary-color;
}

.header ul.icon-menu li.icon-box i {
  color: #ddd;
  font-size: 19px;
  transition: 0.3s;
}

.header ul.icon-menu li.icon-box.react-tabs__tab--selected,
.header ul.icon-menu li.icon-box:hover,
.header ul.icon-menu li.icon-box.react-tabs__tab--selected i,
.header ul.icon-menu li.icon-box:hover i {
  color: #fff;
}

.header .icon-box h2 {
  font-size: 15px;
  font-weight: 500;
}

.header .icon-box i.fa {
  position: absolute;
  pointer-events: none;
}

.header .icon-menu i {
  left: 0;
  right: 0;
  margin: 0 auto;
  display: block;
  text-align: center;
  font-size: 35px;
  top: 15px;
}

.header .icon-box h2 {
  z-index: -1;
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0;
  color: #fff;
  line-height: 50px;
  font-weight: 500;
  transition: opacity 0.1s ease-out, right 0 ease-in-out;
  border-radius: 30px;
  text-transform: uppercase;
  padding: 0 25px 0 30px;
  height: 50px;
  margin: 0;
}

.header .icon-box:hover h2 {
  opacity: 1;
  right: 27px;
  margin: 0;
  text-align: center;
  border-radius: 30px 0 0 30px;
  width: 190px;
}
/*** Homepage ***/

.tab-panel_list {
  overflow: hidden;

  @media screen and (max-width: 991px) {
    padding-bottom: 60px;
  }
}

.tab-panel_list > div {
  position: relative;

  &.home {
    @media screen and (max-width: 991px) {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  &::after {
    position: absolute;
    content: "";
    top: 0px;
    left: 0px;
    background-color: #222;
    z-index: 4;
  }
}

.tab-panel_list > div {
  &::after {
    background-color: #eee;
  }
}

.tab-panel_list > div.react-tabs__tab-panel--selected:after {
  animation: anim 0.9s cubic-bezier(0.77, 0, 0.175, 1) both;
  right: 0px;
}

@keyframes anim {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(100%);
  }
}

.header ul.icon-menu li.icon-box.react-tabs__tab--selected,
.header ul.icon-menu li.icon-box:hover,
.btn,
.header .icon-box h2,
.home .color-block,
.button:before,
.button-icon {
  background-color: #fdcb6e;
}

@media screen and (max-width: 991px) {
  .header ul.icon-menu li.icon-box.react-tabs__tab--selected,
  .header ul.icon-menu li.icon-box:hover,
  .header ul.icon-menu li.icon-box.react-tabs__tab--selected,
  .header ul.icon-menu li.icon-box:hover {
    background: #fdcb6e !important;
  }
}
