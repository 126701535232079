@use "../../assets/scss/main/colors" as colors;
.article-wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  &.dark {
    background: #252525;

    &.hero {
      background: #111;
    }

    &.light {
      background: #fff;
    }
  }

  .box_inner {
    height: 100%;
    width: 750px;
    overflow-y: auto;
    border-radius: 5px;

    @media (max-width: 1400px) {
      width: 900px;
    }

    @media (max-width: 992px) {
      width: 700px;
    }

    @media (max-width: 599px) {
      width: 460px;
    }

    @media (max-width: 490px) {
      width: 360px;
    }

    @media (max-width: 420px) {
      width: 320px;
    }

    &.blog-post {
      padding: 0 50px;

      @media (max-width: 767px) {
        padding: 0 15px;
      }
    }
  }
}

.meta {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 10px;
  font-size: 0.9rem;
  color: #7f7f7f;

  span,
  time {
    display: flex;
    align-items: center;
    gap: 5px;

    i {
      color: #ffb400;
    }
  }

  body.light & {
    color: #888;

    i {
      color: colors.$primary-color;
    }
  }
}

/* Image Section */
.img-fluid {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 5px;

  @media (min-width: 1350px) {
    background: #ddd;
    min-height: 396px;
  }

  @media (max-width: 450px) {
    min-height: 173px;
  }
}

/* Blog Excerpt Section */
.blog-excerpt {
  line-height: 1.6;
  font-size: 1rem;
  color: #333;

  div {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    white-space: pre-wrap;
  }

  .quotebox {
    display: flex;
    align-items: flex-start;
    gap: 15px;
    margin-top: 20px;
    padding: 15px;
    border-left: 4px solid #ffb400;
    border-radius: 5px;
    width: 100%;
    background: #fff;

    div {
      flex: 1;
      z-index: 1000;
      border: none;
      p {
        margin: 0;
        font-size: 1rem;
        font-style: italic;
        color: #555;
        line-height: 1.5;
      }
    }
  }

  /* Blog Post Article */
  .blog-post article {
    h1 {
      font-size: 20px;
      font-weight: 600;
      margin: 15px 0 25px;
      line-height: 1.3;

      body.light & {
        color: #666;
        background-color: #eee;
        border: 1px solid #eee;
      }
    }
  }

  /* Scrollbar Customization */
  .article-wrapper.dark .box_inner::-webkit-scrollbar {
    width: 8px; /* Width of the entire scrollbar */
  }

  .article-wrapper.dark .box_inner::-webkit-scrollbar-track {
    background: #333; /* Color of the scrollbar track */
  }

  .article-wrapper.dark .box_inner::-webkit-scrollbar-thumb {
    background-color: #555; /* Color of the scroll thumb */
    border-radius: 20px; /* Roundness of the scroll thumb */
    border: 1px solid #333; /* Padding around the scroll thumb */
  }

  body.light .article-wrapper.dark .box_inner::-webkit-scrollbar-track {
    background: #fff; /* Color of the scrollbar track for light mode */
  }

  body.light .article-wrapper.dark .box_inner::-webkit-scrollbar-thumb {
    background-color: #bbb; /* Color of the scroll thumb for light mode */
    border: 1px solid #fff;
  }
}
