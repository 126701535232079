.blog-actions_icon-buttons__wrapper {
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(4px);
  border-radius: 5px;
  padding: 5px;
  width: 30px;
  height: "auto";
  display: flex;
  direction: column;
  justify-content: center;

  .blog-actions_icon-button {
    background: transparent;
    border: 0 solid black;
    border-radius: 50%;
    width: 40px;
    height: "auto";
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    svg {
      color: rgb(243, 240, 240);
      font-size: 20px;
    }

    &:hover svg {
      color: rgba(180, 176, 176, 0.4);
    }

    &.isArticleLiked {
      svg {
        color: red;
        fill: red;
      }

      &:hover svg {
        color: darkred;
        fill: darkred;
      }
    }
  }
}
