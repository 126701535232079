@use "../../assets/scss/main/colors" as colors;

ul.social {
  margin-left: -5px;
  padding-top: 15px !important;

  li {
    display: inline-block;

    a {
      display: inline-block;
      height: 40px;
      width: 40px;
      line-height: 42px;
      text-align: center;
      color: #fff;
      transition: 0.3s;
      font-size: 17px;
      margin: 0 6px;
      background: colors.$primary-color;
      border-radius: 50%;

      &:hover {
        color: #fff;
        background: darken(colors.$primary-color, 10%);
      }
    }
  }
}

body.light .contact ul.social li a {
  color: #666;
  background: #eee;

  &:hover {
    color: #fff;
    background: colors.$primary-color;
  }
}
