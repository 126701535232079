@use "../../assets/scss/main/colors" as colors;

.login-card_wrapper {
  padding-bottom: 100px;
  .login-card {
    background-color: colors.$white;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 2rem;
    max-width: 400px;
    width: 100%;
    margin: 1.5rem auto;
    border: 1px solid colors.$border-color;
  }

  .login-card_form {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    input {
      background-color: colors.$light-gray;
      padding: 0.75rem;
      font-size: 1rem;
      border-radius: 8px;
      color: colors.$dark-gray;

      &:focus {
        border-color: colors.$primary-color;
        outline: none;
        box-shadow: 0 0 5px rgba(205, 95, 47, 0.5);
      }
    }

    .ant-input-affix-wrapper {
      background-color: colors.$light-gray;
      border-radius: 8px;

      &:focus-within {
        border-color: colors.$primary-color;
        box-shadow: 0 0 5px rgba(205, 95, 47, 0.5);
      }
    }
  }

  .login-card_form button {
    background-color: colors.$primary-color;
    border: none;
    border-radius: 8px;
    color: colors.$white;
    padding: 0.75rem 1.5rem;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
    font-size: 1rem;
    font-weight: bold;

    &:hover {
      background-color: colors.$hover-primary;
      transform: scale(1.02);
    }

    &:disabled {
      background-color: colors.$disabled-color;
      cursor: not-allowed;
    }
  }

  .login-card_button-submit {
    background-color: transparent;
    border: none;
    color: colors.$secondary-color;
    cursor: pointer;
    font-size: 1rem;
    font-weight: bold;
    transition: color 0.3s ease;

    &:hover {
      color: colors.$hover-secondary;
      text-decoration: underline;
    }
  }
}
