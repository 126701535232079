.blog_container {
  max-width: 1200px;
  margin: auto;
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.blog_container-add {
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

  > button {
    font-size: 1rem;
    padding: 10px 20px;
  }
}

.blog_container-articles_wrapper {
  height: auto;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));
  gap: 20px;
  margin: 0 auto;
  margin-left: auto;
  .blog_container-articles_skeleton {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 100%;
    max-width: 1600px;
  }
}

@media (min-width: 1800px) {
  .blog_container {
    max-width: 1600px;
  }
}

@media (max-width: 1200px) {
  .blog_container-articles_wrapper {
    margin-bottom: 100px;
  }
}

@media (max-width: 768px) {
  .blog_container-add {
    padding-bottom: 20px;
  }

  .blog_container-articles_wrapper {
    justify-content: center;
  }
}

@media (max-width: 576px) {
  .blog_container-add > button {
    width: 100%;
    font-size: 0.9rem;
  }
}
