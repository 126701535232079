@use "colors";

/* [ GENERAL ] */
/*================================================== */

/*** Miscellaneous ***/

* {
  cursor: none !important;
}

html {
  overflow-x: hidden;
  height: 100vh;
}

body {
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  color: #000;
  font-weight: 500;
  line-height: 1.6;
  position: relative;
  background-size: cover;
  background-position: center;
  height: 100vh;
  width: 100%;
  background-color: colors.$body-background-color;
  margin-bottom: 50px;
}

.poppins-font {
  font-family: "Poppins", sans-serif;
}
.roboto-font {
  font-family: "Roboto", sans-serif;
}

.open-sans-font {
  font-family: "Open Sans", sans-serif;
}

img {
  max-width: 100%;
}

hr.separator {
  border-top: 1px solid #252525;
  margin: 70px auto 55px;
  max-width: 40%;

  &.separator-margin {
    margin: 10px auto 40px;
  }
}

.ft-wt-600 {
  font-weight: 600;
}

.mb-30 {
  margin-bottom: 30px;
}

.pb-50 {
  padding-bottom: 50px;
}

.no-transform {
  transform: none !important;
}

.custom-title {
  padding-bottom: 22px;
  font-size: 26px;
}

.cursor-none {
  cursor: none !important;
}

.custom-overlay .green {
  .icon {
    img {
      filter: invert(0);
    }
  }
}

.quotebox {
  position: relative;
  padding-left: 70px;
  margin-top: 35px;
  margin-bottom: 35px;

  .icon {
    position: absolute;
    left: 0;
    top: 10px;

    img {
      width: 40px;
      height: 40px;
      filter: invert(1);
    }
  }

  p {
    font-size: 17px;
    font-style: italic;
    margin-bottom: 23px;
  }
}

/*** Buttons ***/
.button {
  overflow: hidden;
  display: inline-block;
  line-height: 1.4;
  border-radius: 35px;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  transition: 250ms all ease-in-out;
  text-transform: uppercase;
  text-decoration: none !important;
  position: relative;
  z-index: 1;
  padding: 16px 70px 16px 35px;
  font-size: 15px;
  font-weight: 600;
  color: #fff;
  background-color: transparent;
  outline: none !important;
}

.button .button-text {
  position: relative;
  z-index: 2;
  color: #cd5f2f;
  transition: 0.3s ease-out all;
}

.button:before {
  z-index: -1;
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transform: translateX(100%);
  transition: 0.3s ease-out all;
}

.button:hover .button-text {
  color: #ffffff;
}

.button:hover:before {
  transform: translateX(0);
}

.button-icon {
  position: absolute;
  right: -1px;
  top: -1px;
  bottom: 0;
  width: 55px;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 19px;
  color: #ffffff;
  border-radius: 50%;
}

.toast {
  display: flex;
}

/* Mobile  Navigation */

@media (max-width: 576px) {
  .custom-title {
    font-size: 21px;
  }

  .separator {
    margin-top: 35px !important;
  }
}

// ohters
// ul.row.grid,
// .row.pb-50 {
//   min-height: 700px;
// }

body::-webkit-scrollbar {
  width: 12px;
  display: none;
  /* width of the entire scrollbar */
}

body::-webkit-scrollbar-track {
  background: #333;
  /* color of the tracking area */
}

body::-webkit-scrollbar-thumb {
  background-color: #555;
  /* color of the scroll thumb */
  border-radius: 20px;
  /* roundness of the scroll thumb */
  border: 3px solid #333;
  /* creates padding around scroll thumb */
}

button:focus {
  outline: none !important;
}

// bootstrap 5 customization
.container {
  max-width: 1200px;
}

.row > * {
  padding-left: 15px;
  padding-right: 15px;
}
