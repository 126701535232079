@use "../../assets/scss/main/colors" as colors;

.ma-method {
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: colors.$body-background-color;
  color: colors.$dark-gray;
  padding: 0 0 200px 0;

  .content-container {
    width: 50vw;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .intro-section {
      background: colors.$white;
      border-radius: 10px;
      padding: 20px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      text-align: justify;
      font-size: 1rem;
      line-height: 1.8;
    }

    .step-card {
      background: colors.$white;
      border-radius: 10px;
      padding: 15px 20px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      cursor: pointer;
      transition: transform 0.3s ease;

      &.expanded {
        transform: scale(1.02);
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
      }

      .step-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 1.2rem;
        font-weight: bold;
        color: colors.$primary-color;

        span {
          font-size: 1.2rem;
          color: colors.$secondary-color;
        }
      }

      .step-content {
        margin-top: 15px;
        font-size: 1rem;
        line-height: 1.6;
        color: colors.$dark-gray;

        ul {
          list-style: none;

          li {
            position: relative;
            margin-bottom: 10px;
            padding-left: 20px;

            &::before {
              content: "👉";
              position: absolute;
              left: 0;
              color: colors.$secondary-color;
            }
          }
        }
      }
    }
  }

  @media (max-width: 991px) {
    .content-container {
      width: 90vw;
    }
  }
}
