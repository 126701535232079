$primary-bg-color: #252525;
$secondary-bg-color: #f2f2f2;
$text-color-light: #fff;
$text-color-dark: #666;
$highlight-color: #eee;
$border-radius: 5px;

.blog-page_article-card {
  display: flex;
  flex-direction: column;
  border-radius: $border-radius;
  overflow: hidden;
  background-color: $secondary-bg-color;
  box-shadow: 6px 10px 8px -1px rgba(0, 0, 0, 0.18);
  &__post-thumbnail {
    width: 100%;
    height: 75%;
    aspect-ratio: 16 / 9;
    background-color: $highlight-color;

    img {
      width: 100%;
      height: 100%;
      object-fit: fit;
      border-radius: $border-radius $border-radius 0 0;
      transition: transform 0.3s ease;

      &:hover {
        transform: scale(1.1);
      }
    }
  }

  &__title-wrapper {
    background-color: $primary-bg-color;
    padding: 15px 20px;
    border-radius: 0 0 $border-radius $border-radius;
    flex-grow: 1;
    display: flex;
    align-items: center;
    text-align: left;
    z-index: 3;
    height: 25%;
  }

  &__title {
    h3 {
      font-size: 18px;
      font-weight: 600;
      color: $text-color-light;
      line-height: 24px;
      margin: 0;
    }
  }
}

@media screen and (min-width: 1800px) {
  .blog-page_article-card {
    &__post-thumbnail {
      aspect-ratio: 16 / 9;
    }

    &__title-wrapper {
      padding: 20px 25px;
    }
  }
}

@media screen and (max-width: 768px) {
  .blog-page_article-card {
    &__post-thumbnail {
      aspect-ratio: 4 / 3;
    }
  }
}

@media screen and (max-width: 450px) {
  .blog-page_article-card {
    height: 360px;

    &__title-wrapper {
      padding: 15px;
    }
  }
}
