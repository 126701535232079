@use "../../assets/scss/main/colors" as colors;
.title-section {
  margin: 0 auto;
  width: 100%;
  position: relative;
  padding: 20px; /* Ajustado para una apariencia más moderna */
  text-align: center; /* Centrado para una disposición estándar */
}

.title-section h1 {
  font-size: 48px;
  font-weight: 800; /* Ajustado para un peso más moderado */
  color: colors.$secondary-color;
  text-transform: uppercase;
  margin: 0;
  line-height: 1.2; /* Mejora la legibilidad */

  span {
    color: colors.$primary-color;
    font-weight: 900; /* Resalta los elementos span */
  }
}

.subtitle {
  font-size: 1rem; /* Tamaño ligeramente menor para complementar el título */
  color: colors.$black;
  margin-top: -20px; /* Ajuste para evitar espacios negativos */
  line-height: 1.5; /* Mejor legibilidad */
  font-weight: 400;
}

@media (max-width: 576px) {
  .title-section {
    padding: 40px 15px;
  }

  .title-section h1 {
    font-size: 32px;
  }

  .subtitle {
    font-size: 0.9rem;
  }
}
