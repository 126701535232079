// Colores principales (base del diseño)
$primary-color: #cd5f2f; // Color principal
$secondary-color: #ffb400; // Color secundario
$body-background-color: #ffeaa7; // Fondo del cuerpo

// Colores adicionales
$light-gray: #f9f9f9; // Fondo claro
$dark-gray: #3a3a3a; // Texto y detalles oscuros
$border-color: #e0e0e0; // Bordes suaves
$hover-primary: lighten($primary-color, 10%); // Hover para el color principal
$hover-secondary: darken(
  $secondary-color,
  10%
); // Hover para el color secundario
$disabled-color: #cccccc; // Elementos deshabilitados
$white: #ffffff; // Blanco puro
$black: #000000; // Negro puro
